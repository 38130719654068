<template>
    <div id="parts-usage-bg" class="fill-height">
        <v-overlay :absolute="true" :value="noOrgInfo">
            <org-overlay></org-overlay>
        </v-overlay>
        <v-overlay :absolute="true" :value="$apollo.loading">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </v-overlay>
        <v-container>
            <v-responsive
                max-width="1000"
                width="100%"
                style="display: block; margin: auto"
            >
                <parts-usage-stepper
                    v-if="!noModels && !errors"
                    :models="models"
                ></parts-usage-stepper>
                <v-card v-if="noModels" style="margin-top: 25px">
                    <v-card-title class="text-h5">
                        No registered devices
                    </v-card-title>

                    <v-card-subtitle
                        >You currently have no registered devices. <br />
                        Please visit
                        <a @click="toRegister"
                            >"Your Account > Product Registration"</a
                        >
                        to register your devices.</v-card-subtitle
                    >
                </v-card>
                <v-card v-if="errors && !noOrgInfo" style="margin-top: 25px">
                    <v-card-title class="text-h5">
                        Error retrieving registered devices
                    </v-card-title>

                    <v-card-subtitle>{{ errorMessage }}</v-card-subtitle>
                </v-card>
            </v-responsive>
        </v-container>
    </div>
</template>

<script>
import OrgOverlay from '../../components/no_org_overlay'
import PartsUsageStepper from '../../components/parts_usage_stepper/index.vue'
import gql from 'graphql-tag'

export default {
    name: `PartsUsage`,

    components: {
        PartsUsageStepper,
        OrgOverlay
    },
    apollo: {
        queryOrg: {
            query: gql`
                query QueryOrg {
                    queryOrg {
                        gssOrgId
                        orgId
                        orgName
                    }
                }
            `,
            update(data) {
                if (!data.queryOrg) {
                    this.noOrgInfo = true
                }
            }
        },
        querySMModel: {
            query: gql`
                query Query {
                    querySMModel {
                        model
                        iwQty
                        oowQty
                    }
                }
            `
        }
    },
    data() {
        return {
            models: [],
            noModels: false,
            errors: false,
            errorMessage: '',
            noOrgInfo: false
        }
    },
    created() {
        this.$apollo
            .query({
                query: gql`
                    query Query {
                        querySMModel {
                            model
                            iwQty
                            oowQty
                        }
                    }
                `
            })
            .then((data) => {
                if (data.data.querySMModel.length === 0) {
                    this.noModels = true
                }
                this.models = data.data.querySMModel
            })
            .catch((errors) => {
                this.errors = true
                this.errorMessage = errors.message
            })
    },
    methods: {
        toRegister() {
            this.$router.push({ path: '/your-account/product-registration' })
        }
    }
}
</script>

<style scoped>
#parts-usage-bg {
    background-color: #485461;
    background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);
}
</style>
